<template>
  <v-dialog v-if="transDialog" v-model="transDialog" :max-width="maxWidth || 515">
    <v-card class="pa-5" :style="{ aspectRatio: '4/3', overflowY: 'hidden' }">
      <template v-if="isLoading">
        <div class="pb-3">
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </div>
      </template>
      <div class="d-flex flex-column">
        <div class="d-flex align-center justify-start mb-7">
          <h1 class="text-center flex-grow-1">Course Materials Delivery</h1>
          <v-btn icon @click="closePopup"><v-icon>mdi-close</v-icon></v-btn>
        </div>
        <v-divider></v-divider>
        <v-row class="mt-5">
          <v-col cols="6">
            <div class="d-flex align-center">
              <h3 class="mr-3">Student Code:</h3>
              <p>{{ dataTable.studentCode }}</p>
            </div>
          </v-col>
          <v-col cols="6">
            <div class="d-flex align-center">
              <h3 class="mr-3">Student Name:</h3>
              <p>{{ dataTable.studentName }}</p>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <div class="d-flex align-center">
              <h3 class="mr-3">Class Code:</h3>
              <p>{{ dataTable.classCode | hasValue }}</p>
            </div>
          </v-col>
          <v-col cols="6">
            <div class="d-flex align-center">
              <h3 class="mr-3">Program:</h3>
              <p>{{ dataTable.programme }}</p>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-data-table
        v-if="!isLoading"
        :headers="headers"
        :items="dataTable.materialList"
        :items-per-page="1000000"
        hide-default-header
        hide-default-footer
        class="elevation-0 my-5 bookTable"
        fixed-header
        height="49vh"
        id="bookTable"
      >
        <template v-slot:header="{}">
          <thead style="border-radius: 20px; text-align: center" class="v-data-table-header">
            <tr>
              <th
                v-for="(header, index) in headers"
                :key="index"
                :style="{
                  background: '#0b2585',
                  color: '#fff',
                  textAlign: 'center',
                  width: `${header.width}`,
                  maxWidth: `${header.width}`,
                }"
                v-html="header.text"
              ></th>
            </tr>
          </thead>
          <!-- <tr class="fixed-book-filter">
            <th v-for="header in headers" :key="header.text">
              <Autocomplete
                v-if="autoFilters.hasOwnProperty(header.value)"
                @setFilter="setAutoFilter"
                :styleBorder="'border-bottom'"
                :keyFilter="header.value"
                :selectedInit="autoFilters[header.value]"
                :listItem="groupColumnValueList(header.value)"
              />
            </th>
          </tr> -->
        </template>
        <template v-slot:body="{ items }">
          <tbody v-if="items.length === 0" style="text-align: center">
            <div class="pt-3" style="position: absolute; left: 50%; transform: translate(-50%, 0)">
              No data available
            </div>
          </tbody>
          <tbody v-else>
            <tr v-for="(item, index) in items" :key="index" class="row-sale">
              <td>
                {{ index + 1 }}
              </td>
              <td>
                {{ item.productCode }}
              </td>
              <td>
                {{ item.description }}
              </td>
              <td>
                <div class="d-flex align-center justify-center">
                  <img v-if="item.paidDate" class="mx-2" :src="iconAc" alt="" srcset="" />
                  {{ item.paidDate ? 'Yes' : 'Not Yet' }}
                </div>
              </td>
              <td>
                <p v-if="item.paidDate">{{ item.paidDate | getDate }}</p>
                <p v-else>null</p>
              </td>
              <td class="d-flex align-center justify-center">
                <v-checkbox v-model="item.received" @change="getToday(item)"></v-checkbox>
              </td>
              <td>
                <div class="d-flex align-center justify-end">
                  <!-- <p v-if="item.received">{{ item.receiveDate | getDate }}</p> -->
                  <v-text-field
                    v-if="item.received"
                    class="ml-3"
                    type="date"
                    v-model="item.receiveDate"
                    :rules="[v => !!v || 'received date is required']"
                    outlined
                    dense
                    hide-details
                    style="max-width: 200px"
                  />

                  <div class="d-flex align-center justify-end" v-else>
                    <p>null</p>
                    <img
                      class="mx-2"
                      :src="require('../../assets/images/material-calendar.svg')"
                      :style="{ opacity: item.received ? 1 : 0.5 }"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-footer
        :style="{ position: 'sticky !important' }"
        height="60"
        style="width: 100%"
        color="#fff"
        class="pl-10 pr-10"
      >
        <v-row justify="center" no-gutters>
          <v-btn
            elevation="2"
            class="mr-3 px-10"
            color="primary"
            @click="saveStatus"
            :disabled="isDisabledSave"
          >
            Save
          </v-btn>
          <v-btn
            color="primary"
            elevation="2"
            outlined
            class="mr-3 px-10"
            @click="transDialog = false"
            >Cancel</v-btn
          >
        </v-row>
      </v-footer>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from 'dayjs'
import { book_management } from '@/api/book_management'
// import DatePicker from 'vue2-datepicker'
import ViewDetail from '@/mixins/viewDetail.js'
import ConfigDate from '@/mixins/configDate.js'
import iconAc from '../../assets/images/icon-accept.svg'
import iconUnAc from '../../assets/images/icon-unaccept.svg'
// import Autocomplete from '@/components/Utils/Autocomplete.vue'

export default {
  mixins: [ConfigDate, ViewDetail],
  // components: { DatePicker },
  props: {
    height: String,
    maxWidth: String,
    maxHeight: String,
    materialDetail: Object,
  },
  data() {
    return {
      currentRole: JSON.parse(localStorage.getItem('currentUser')).userInfo.accountTypeID,
      dataTable: this.materialDetail ? this.materialDetail : [],
      transDialog: false,
      isLoading: false,
      iconAc: iconAc,
      iconUnAc: iconUnAc,
      studentID: 0,
      classID: 0,
      //   autoFilters: {
      //     productCode: [],
      //     description: [],
      //     paidStatus: [],
      //     receivedStatus: [],
      //     receivedDate: []
      //   },
      //setting table
      headers: [
        {
          text: 'No',
          value: 'no',
          width: '50px',
          sortable: false,
        },
        {
          text: 'Product Code',
          value: 'productCode',
          sortable: false,
        },
        {
          text: 'Product Name',
          value: 'description',
          width: '400px',
          sortable: false,
        },
        {
          text: 'Paid ?',
          value: 'paidStatus',
          width: '100px',
          sortable: false,
        },
        {
          text: 'Paid date',
          value: 'paidDate',
          width: '100px',
          sortable: false,
        },
        {
          text: 'Received ?',
          value: 'received',
          width: '200px',
          sortable: false,
        },
        {
          text: 'Received date',
          value: 'receiveDate',
          width: '300px',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    // filtered() {
    //   return this.dataTable?.filter(d => {
    //     return Object.keys(this.autoFilters).every(f => {
    //       return this.autoFilters[f].length < 1 || this.autoFilters[f].includes(d[f])
    //     })
    //   })
    // },
    isDisabledSave() {
      return this.dataTable.materialList.length > 0
        ? this.dataTable.materialList.filter(el => el.received).some(el => !el.receiveDate)
        : false
    },
  },
  created() {},
  watch: {
    transDialog(val) {
      if (val === false) {
        this.transDialog = false
        this.dataTable = []
      }
    },
  },
  methods: {
    // setAutoFilter(objectFilterChange) {
    //   this.autoFilters = {
    //     ...this.autoFilters,
    //     [objectFilterChange.name]: objectFilterChange.filter,
    //   }
    // },
    // groupColumnValueList(val) {
    //   if (val === 'qtyOrd') {
    //     return [...new Set(this.dataTable.map(d => d[val]))]
    //   }
    //   return this.dataTable.map(d => d[val]).filter(y => y)
    // },
    getToday(object) {
      if (object.received === true) object.receiveDate = dayjs().format('YYYY-MM-DD')
    },
    async saveStatus() {
      const data = this.dataTable.materialList.map(el => {
        return el.received
          ? {
              productCode: el.productCode,
              receivedStatus: el.received,
              receiveDate: el.receiveDate,
              qty: 1,
              remarks: '',
            }
          : {
              productCode: el.productCode,
              receivedStatus: el.received,
            }
      })
      await book_management.postStudentDeliveryStatus(this.classID, this.studentID, data)
      this.$emit('callData')
      this.closePopup()
    },
    async open(classID, studentID) {
      this.isLoading = true
      this.classID = classID
      this.studentID = studentID
      // this.dataTable = await book_management.getMaterialDeliveryStudent(classID, studentID)
      await book_management.getMaterialDeliveryStudent(classID, studentID).then(res => {
        const mtList = res.materialList.map(el => {
          return {
            ...el,
            receiveDate: dayjs(el.receiveDate).format('YYYY-MM-DD'),
          }
        })
        this.dataTable = { ...res, ...{ materialList: mtList } }
      })
      this.isLoading = false
      this.transDialog = true
    },
    closePopup() {
      this.transDialog = false
      //   this.isLoading = false
      this.dataTable = []
    },
    // async init() {
    //   this.isLoading = true
    //   await book_management.getTransferDateByID(this.transDetail.tranNbr).then(res => {
    //     this.dataTable = res
    //   })
    // },
    _dayjs: dayjs,
  },
}
</script>
<style lang="scss" scoped>
.book-inventory h1 {
  font-size: 21px;
}

.select-item {
  // margin-right: 10px;
  max-width: 200px;
}
.multiple-select {
  margin-right: 10px;
  max-width: 400px;
}

.book-inventory {
  margin: 0 20px;
}

.fixed-book-filter {
  position: sticky;
  top: 58px;
  z-index: 2;
  background-color: #ffffff;
}
.row-overall {
  td {
    text-align: center;
    p {
      font-weight: bold;
      display: inline-block;
    }
  }
}
tbody {
  tr.row-sale {
    &:nth-child(odd) {
      background-color: #fff;
    }
    background-color: #f3faff;

    td {
      text-align: center;
    }
  }
}

.bookTableFoot {
  td {
    text-align: center;
    font-weight: bold;
  }
}
</style>
