const _ = require('lodash')
export default {
    name: 'ViewDetail',
    data() {
        return {
            isError: false,
        }
    },
    filters: {
        capitalize: function (value) {
            if (!value) return ''
            return _.capitalize(value)
        },
        hasValue: function (value) {
            if (!value) return '-'
            return value
        }
    },
    methods: {
        viewDetailUser(userId) {
            if (userId) {
                this.$router.push({
                    name: 'account-management-view-account',
                    params: {
                        userId: userId
                    },
                })
            } else {
                this.isError = true
                setTimeout(()=>{
                    this.isError = false
                },1000)
            }
        },
        viewDetailProfile(userId, tab) {
            this.$router.push({
                name: 'account-management-view-account',
                params: {
                    userId: userId,
                },
                query: {
                    tab: tab
                },
            })
        },
        viewDetailClass(classID) {
            this.$router.push({
                name: 'class-management-detail',
                query: {
                    classID: classID
                },
            })
        },
    },
}