import {
  handleResponse,
  handleResponseBinary
} from './handle-response'

const {
  ROOT,
  BOOK_INVENTORY,
  INVTORDERBYCENTER,
  GET_INVENTORY_DETAIL,
  GET_INVENTORY_ITEM,
  GET_CASH_DETAIL,
  GET_TRANSFER_DATA,
  GET_TRANSFER_DATA_BY_ID,
  GET_COURSE_MATERIAL_CHECK_LIST,
  GET_COURSE_MATERIAL_DELIVERY_BY_STUDENT,
  POST_STUDENT_DELIVERY_STATUS,
  GET_CLASS_MATERIAL_STATUS,
  GET_CLASS_MATERIAL_DETAIL,
  EXPORT_MATERIAL_DATA
} = require('./constant')
const {
  requestOptions
} = require('./request-options')

function getBookInventory(locationID, month, year) {
  return fetch(
      ROOT + BOOK_INVENTORY + `LocationID=${locationID}&Month=${month}&Year=${year}`,
      requestOptions.get()
    )
    .then(handleResponse)
    .then(data => {
      return data
    })
}

function getInvtOrderByCenter(locationID, fromDate, toDate) {
  return fetch(
      ROOT + INVTORDERBYCENTER + `LocationID=${locationID}&fromDate=${fromDate}&toDate=${toDate}`,
      requestOptions.get()
    )
    .then(handleResponse)
    .then(data => {
      return data
    })
}

function getInventoryDetail(locationId, month, year) {
  return fetch(
      ROOT + GET_INVENTORY_DETAIL + `locationId=${locationId}&month=${month}&year=${year}`,
      requestOptions.get()
    )
    .then(handleResponse)
    .then(data => {
      return data
    })
}

function getInventoryItem(locationID, month, year) {
  return fetch(
      ROOT + GET_INVENTORY_ITEM + `locationId=${locationID}&month=${month}&year=${year}`,
      requestOptions.get()
    )
    .then(handleResponse)
    .then(data => {
      return data
    })
}

function getCashDetail(locationID, fromDate, toDate) {
  return fetch(
      ROOT + GET_CASH_DETAIL + `locationId=${locationID}&fromDate=${fromDate}&toDate=${toDate}`,
      requestOptions.get()
    )
    .then(handleResponse)
    .then(data => {
      return data
    })
}

function getTransferDate(fromCenterId, toCenterId, fromDate, toDate, tranStatus, ReasonId, PurOrdId) {
  return fetch(
      ROOT + GET_TRANSFER_DATA +
      `${fromCenterId ? `fromCenterId=${fromCenterId}&` :''}` +
      `${toCenterId ? `toCenterId=${toCenterId}&` :''}` +
      `${fromDate ? `fromDate=${fromDate}&` :''}` +
      `${toDate ? `toDate=${toDate}` :''}` +
      `${tranStatus!=='All' ? `&tranStatus=${tranStatus}` :''}` +
      `${ReasonId ? `&ReasonId=${ReasonId}` :''}` +
      `${PurOrdId ? `&PurOrdId=${PurOrdId}` :''}`,
      requestOptions.get()
    )
    .then(handleResponse)
    .then(data => {
      return data
    })
}

function getTransferDetailByID(transNbr) {
  return fetch(
      ROOT + GET_TRANSFER_DATA_BY_ID + `transNbr=${transNbr}`,
      requestOptions.get()
    )
    .then(handleResponse)
    .then(data => {
      return data
    })
}

function getMaterialCheckList(ClassID) {
  return fetch(
      ROOT + GET_COURSE_MATERIAL_CHECK_LIST + `ClassID=${ClassID}`,
      requestOptions.get()
    )
    .then(handleResponse)
    .then(data => {
      return data
    })
}

function getMaterialDeliveryStudent(ClassId, StudentId) {
  return fetch(
      ROOT + GET_COURSE_MATERIAL_DELIVERY_BY_STUDENT + `ClassId=${ClassId}&StudentId=${StudentId}`,
      requestOptions.get()
    )
    .then(handleResponse)
    .then(data => {
      return data
    })
}

function getClassMaterialStatus(ClassId) {
  return fetch(
      ROOT + GET_CLASS_MATERIAL_STATUS + `ClassId=${ClassId}`,
      requestOptions.get()
    )
    .then(handleResponse)
    .then(data => {
      return data
    })
}

function getClassMaterialDetail(ClassId, productId = 0) {
  return fetch(
      ROOT + GET_CLASS_MATERIAL_DETAIL + `ClassId=${ClassId}&productId=${productId}`,
      requestOptions.get()
    )
    .then(handleResponse)
    .then(data => {
      return data
    })
}

function exportMaterialData(ClassId, name) {
  return fetch(
      ROOT + EXPORT_MATERIAL_DATA + `ClassId=${ClassId}`,
      requestOptions.getBinary()
    )
    .then(response => response.blob())
    .then(res => handleResponseBinary(res, name))
    .then(data => {
      return data
    })
}

function postStudentDeliveryStatus(ClassId, StudentId, data) {
  return fetch(
      ROOT + POST_STUDENT_DELIVERY_STATUS + `ClassId=${ClassId}&StudentId=${StudentId}`,
      requestOptions.postBody(data)
    )
    .then(handleResponse)
    .then(data => {
      return data
    })
}
export const book_management = {
  getBookInventory,
  getInvtOrderByCenter,
  getInventoryDetail,
  getInventoryItem,
  getCashDetail,
  getTransferDate,
  getTransferDetailByID,
  getMaterialCheckList,
  getMaterialDeliveryStudent,
  postStudentDeliveryStatus,
  getClassMaterialStatus,
  getClassMaterialDetail,
  exportMaterialData
}