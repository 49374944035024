<template>
  <v-dialog v-if="testInfo.length > 0" v-model="testDialog" :max-width="maxWidth || 515">
    <v-card class="pa-5" :style="{ height: '80vh', overflowY: 'scroll' }">
      <template v-if="loading">
        <div class="pb-3">
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </div>
      </template>
      <div class="d-flex flex-column">
        <div class="d-flex align-center justify-start mb-4">
          <h1 class="text-center flex-grow-1">{{ testInfo[0].masterType }}</h1>
          <v-btn icon @click="closePopup"><v-icon>mdi-close</v-icon></v-btn>
        </div>
        <v-row>
          <v-col cols="5">
            <v-row>
              <v-col>
                <div class="d-flex align-center">
                  <h3 class="mr-3">Student:</h3>
                  <p>{{ testInfo[0].studentName }} - {{ testInfo[0].studentCode }}</p>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="d-flex align-center">
                  <h3 class="mr-3">Class:</h3>
                  <p>{{ testInfo[0].classCode }}</p>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="7">
            <v-row>
              <div class="d-flex align-center">
                <v-col>
                  <h3 class="mr-3">Program:</h3>
                </v-col>
                <p>{{ testInfo[0].programme ? testInfo[0].programme : '-' }}</p>
              </div>
            </v-row>
            <v-row>
              <div class="d-flex flex-grow-1">
                <v-col cols="3">
                  <h3>Test Date:</h3>
                </v-col>
                <v-col cols="9">
                  <div
                    v-for="(test, index) in getUniqueDate()"
                    :key="index"
                    style="display: inline-block"
                  >
                    <span class="d-flex align-center">
                      <p>{{ _dayjs(test).format('DD/MM/YYYY') }}</p>
                      <p v-if="index !== getUniqueDate().length - 1" class="px-2">and</p>
                    </span>
                  </div>
                </v-col>
              </div>
            </v-row>
          </v-col>
        </v-row>
        <div>
          <h3 class="mt-4">Score:</h3>
          <div class="mt-3 d-flex align-center justify-space-between flex-wrap" style="gap: 10px">
            <div
              v-for="(test, index) in testInfo"
              :key="index"
              :style="{
                minWidth:
                  testInfo.length === 1
                    ? '100%'
                    : testInfo.length < 4
                    ? `${100 / testInfo.length - 1}%`
                    : '23%',
              }"
            >
              <div v-if="currentRole === 4 || currentRole === 5">
                <v-text-field
                  v-model="test.mark"
                  outlined
                  :disabled="currentRole === 1 || currentRole === 2"
                  :label="test.testType"
                  :rules="validationRules"
                  max="10"
                  min="0"
                  type="number"
                ></v-text-field>
              </div>
              <div v-else>
                <p>{{ `${test.testType} ` }}</p>
                <p>{{ examStatus === 'Public' ? test.mark : '-' }}</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h3 class="mt-2 mb-3">Video test:</h3>
          <div class="d-flex align-center justify-space-between flex-column">
            <template>
              <v-text-field
                outlined
                label="Input link"
                v-model="videoUrl"
                :disabled="currentRole === 1 || currentRole === 2"
                :append-icon="closeIcon"
                @click:append="clearInput"
                ref="weightInput"
                style="border-radius: 7.5px 0px 0px 7.5px; width: 100%"
              >
                <template #append>
                  <v-icon>{{ closeIcon }}</v-icon>
                </template>
              </v-text-field>
            </template>
            <template v-if="videoUrl"
              ><iframe
                :src="urlChange(videoUrl)"
                :style="{
                  height: '400px',
                  width: '100%',
                  overflowY: 'scroll',
                  marginBottom: '20px',
                }"
              ></iframe
            ></template>
          </div>
        </div>
        <div>
          <h3 class="mb-3">Teacher's Comment:</h3>
          <vue-editor
            :style="{
              opacity: currentRole === 1 || currentRole === 2 ? '0.6' : '1',
              borderRadius: '4px',
            }"
            v-if="currentRole === 4 || currentRole === 5"
            v-model="teacherComment"
            id="editor1"
            :placeholder="teacherComment ? '' : 'Add your comments...'"
          >
          </vue-editor>
          <p v-else v-html="teacherComment"></p>
          <p v-if="currentRole === 4 || currentRole === 5" class="text-end flex-grow-1 pb-4">
            Character count:{{ teacherComment ? teacherComment.length - 7 : 0 }}
          </p>
        </div>
        <div v-if="currentRole === 4 || currentRole === 5" class="d-flex">
          <v-btn
            @click="
              saveStatus = 'Public'
              save()
            "
            color="primary"
            class="mr-auto"
            width="150"
            >Send</v-btn
          >
          <v-btn @click="testDialog = false" width="150" class="mr-3">Cancel</v-btn>
          <v-btn
            @click="
              saveStatus = 'Draft'
              save()
            "
            color="primary"
            width="150"
            >Save</v-btn
          >
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from 'dayjs'
import { all_test } from '@/api/all-test.js'
import linkIcon from '@/assets/images/all_test_link.svg'
import { VueEditor } from 'vue2-editor'

export default {
  components: {
    'vue-editor': VueEditor,
  },
  props: {
    height: String,
    maxWidth: String,
    maxHeight: String,
    testInfo: Array,
    videoUrl: String,
    teacherComment: String,
    examStatus: String,
  },
  data() {
    return {
      currentRole: JSON.parse(localStorage.getItem('currentUser')).userInfo.accountTypeID,
      loading: false,
      isImage: false,
      isAudio: false,
      testDialog: false,
      item: null,
      tagList: [],
      saveStatus: '',
      validationRules: [
        v => v <= 10 || 'The mark should not greater than 10',
        v => v >= 0 || 'The mark should not smaller than 0',
      ],
      req: [
        v => v?.indexOf('http') !== -1 || 'Must be link',
        v =>
          v?.indexOf('youtube.com') !== -1 ||
          v?.indexOf('youtu.be') !== -1 ||
          v?.indexOf('drive.google.com') !== -1 ||
          v?.indexOf('canva') !== -1 ||
          "Must be youtube's link, driver's link or canva's link",
      ],
      linkIcon: linkIcon,
      closeIcon: 'mdi-link-variant',
    }
  },
  computed: {
    // videoUrl() {
    //   return this.videoUrl
    // },
    // teacherComment() {
    //   return this.teacherComment
    // },
  },
  created() {},
  watch: {
    testDialog(val) {
      if (val === false) {
        this.testDialog = false
        this.testInfo = []
        this.videoUrl = ''
        this.teacherComment = ''
      }
    },
  },
  methods: {
    clearInput() {},
    getUniqueDate() {
      const dateArr = this.testInfo.map(el => el.testDate)
      return [...new Set(dateArr)]
    },
    open(item) {
      this.testDialog = true
      this.item = item
    },
    closePopup() {
      this.testDialog = false
      this.loading = false
    },
    dynamicWidth(num) {
      return Number(100 / (num + 1))
    },
    urlChange(url) {
      let url2 = url
      if (url2.indexOf('/drive.google.com/file') !== -1) {
        const listLinkArr = url2.split('/')
        listLinkArr.pop()
        url2 = listLinkArr.join('/') + '/preview'
      }
      if (url2.indexOf('youtu') !== -1) {
        if (url2.indexOf('embed') === -1) {
          url2 = this.youtube_parser(url2)
            ? 'https://www.youtube.com' + '/embed/' + this.youtube_parser(url2)
            : url2
        }
      }
      return url2
    },
    youtube_parser(url) {
      var r,
        rx =
          /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/

      r = url.match(rx)
      return r ? r[1] : ''
    },
    async save() {
      this.testInfo
        .sort((a, b) => a.testID - b.testID)
        .forEach(async (el, index) => {
          try {
            const body = {
              recordID: el.recordID,
              studentID: el.studentID,
              testID: el.testID,
              classID: el.classID,
              mark: el.mark,
              teacherComment: index === 0 ? this.teacherComment : '',
              videoUrl: index === 0 ? this.videoUrl : '',
              testTypeID: el.testTypeID,
              masterTypeID: el.masterTypeID,
              testResultStatus: this.saveStatus,
              note: null,
            }
            await all_test
              .updateStudentTest(body)
              .then(res => {
                if (res && index === this.testInfo.length - 1) {
                  this.$emit('callData')
                  this.closePopup()
                }
              })
              .catch(err => {
                console.log(err)
              })
          } catch (error) {
            console.log(error)
          }
        })
    },
    _dayjs: dayjs,
  },
}
</script>
<style lang="scss" scoped>
.blue_text {
  color: #5d88e4;
}
.link {
  background-color: #0b2585;
  padding: 8px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.white_text {
  color: #fff;
}
</style>
