<template>
  <v-data-table
    :headers="displayType === 'done' ? normalHeaders : secondHeaders"
    :items="dataTable"
    :items-per-page="1000000"
    hide-default-header
    hide-default-footer
    class="elevation-0 my-5 bookTable"
    fixed-header
    height="59vh"
    id="bookTable"
  >
    <template v-slot:header="{ props }">
      <thead style="border-radius: 20px; text-align: center" class="v-data-table-header">
        <tr>
          <th
            v-for="(header, index) in props.headers"
            :key="index"
            :style="{
              background: '#0b2585',
              color: '#fff',
              textAlign: 'center',
              width: `${header.width}`,
              maxWidth: `${header.width}`,
            }"
            v-html="header.text"
          ></th>
        </tr>
      </thead>
      <!-- <tr class="fixed-book-filter">
                    <th v-for="header in headers" :key="header.text">
                      <Autocomplete
                        v-if="autoFilters.hasOwnProperty(header.value)"
                        @setFilter="setAutoFilter"
                        :styleBorder="'border-bottom'"
                        :keyFilter="header.value"
                        :selectedInit="autoFilters[header.value]"
                        :listItem="groupColumnValueList(header.value)"
                      />
                    </th>
                  </tr> -->
    </template>
    <template v-slot:[`item.no`]="{ index }">
      <p class="text-center">{{ index + 1 }}</p>
    </template>
    <template v-slot:[`item.paidDate`]="{ item }">
      <p class="text-center">{{ item.paidDate | getDate }}</p>
    </template>
    <template v-slot:[`item.receiveDate`]="{ item }">
      <p class="text-center">{{ item.receiveDate | getDate }}</p>
    </template>
    <template v-slot:[`item.dob`]="{ item }">
      <p class="text-center">{{ item.dob | getDate }}</p>
    </template>
    <template v-slot:[`item.studentName`]="{ item }">
      <p class="link text-center" @click="viewDetailUser(item.stuUserID)">{{ item.studentName }}</p>
    </template>
  </v-data-table>
</template>

<script>
import dayjs from 'dayjs'
import ViewDetail from '@/mixins/viewDetail.js'
import ConfigDate from '@/mixins/configDate.js'
// import Autocomplete from '@/components/Utils/Autocomplete.vue'

export default {
  mixins: [ConfigDate, ViewDetail],
  //   components: { Autocomplete },
  props: {
    dataTable: Array,
    displayType: String,
    statusType: String,
  },
  data() {
    return {
      currentRole: JSON.parse(localStorage.getItem('currentUser')).userInfo.accountTypeID,
      //setting table
      normalHeaders: [
        {
          text: 'No',
          value: 'no',
          width: '50px',
          sortable: false,
        },
        {
          text: 'Student Code',
          value: 'studentCode',
          width: '150px',
          sortable: false,
        },
        {
          text: 'Student name',
          value: 'studentName',
          width: '400px',
          sortable: false,
        },
        {
          text: 'DOB',
          value: 'dob',
          width: '150px',
          sortable: false,
        },
        {
          text: `${this.statusType === 'paid' ? 'Paid date' : 'Received date'}`,
          value: `${this.statusType === 'paid' ? 'paidDate' : 'receiveDate'}`,
          width: '150px',
          sortable: false,
        },
      ],
      secondHeaders: [
        {
          text: 'No',
          value: 'no',
          width: '50px',
          sortable: false,
        },
        {
          text: 'Student Code',
          value: 'studentCode',
          width: '150px',
          sortable: false,
        },
        {
          text: 'Student name',
          value: 'studentName',
          width: '400px',
          sortable: false,
        },
        {
          text: 'DOB',
          value: 'dob',
          width: '150px',
          sortable: false,
        },
        {
          text: 'Phone number',
          value: 'phoneNumber',
          width: '150px',
          sortable: false,
        },
        {
          text: 'Mother name',
          value: 'motherName',
          width: '150px',
          sortable: false,
        },
        {
          text: 'Mother phone',
          value: 'motherPhone',
          width: '150px',
          sortable: false,
        },
        {
          text: 'Father name',
          value: 'fatherName',
          width: '150px',
          sortable: false,
        },
        {
          text: 'Father phone',
          value: 'fatherPhone',
          width: '150px',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    // filtered() {
    //   return this.dataTable?.filter(d => {
    //     return Object.keys(this.autoFilters).every(f => {
    //       return this.autoFilters[f].length < 1 || this.autoFilters[f].includes(d[f])
    //     })
    //   })
    // },
  },
  created() {},
  methods: {
    // setAutoFilter(objectFilterChange) {
    //   this.autoFilters = {
    //     ...this.autoFilters,
    //     [objectFilterChange.name]: objectFilterChange.filter,
    //   }
    // },
    // groupColumnValueList(val) {
    //   if (val === 'qtyOrd') {
    //     return [...new Set(this.dataTable.map(d => d[val]))]
    //   }
    //   return this.dataTable.map(d => d[val]).filter(y => y)
    // },
    _dayjs: dayjs,
  },
}
</script>
<style lang="scss" scoped>
.link {
  cursor: pointer;
  color: #427df2 !important;
  &:hover {
    text-decoration: underline;
    font-weight: 700;
  }
}
.book-inventory h1 {
  font-size: 21px;
}
.book-inventory {
  margin: 0 20px;
}

.fixed-book-filter {
  position: sticky;
  top: 58px;
  z-index: 2;
  background-color: #ffffff;
}

tbody {
  tr{
    &:nth-child(odd) {
      background-color: #fff;
    }
    background-color: #f3faff;

    td {
      text-align: center;
    }
  }
}

</style>
