import {
  ROOT
} from '@/api/constant.js'
import {
  handleResponse
} from '@/api/handle-response'
import {
  requestOptions
} from '@/api/request-options'
import {
  GET_MASTER_TYPE_BY_CLASS,
  GET_STUDENT_TEST_BY_CLASS,
  GET_NOTE_BY_CLASS,
  GET_NOTE_BY_STUDENT,
  GET_STUDENT_TEST_BY_STUDENT,
  UPDATE_STUDENT_TEST,
  UPDATE_EXAM_NOTE,
} from './constant'

function getMasterType(data) {
  return fetch(ROOT + GET_MASTER_TYPE_BY_CLASS + `?classID=${data}`, requestOptions.get())
    .then(handleResponse)
    .then(data => {
      return data
    })
}

function getStudentTest(data) {
  return fetch(ROOT + GET_STUDENT_TEST_BY_CLASS + `?classID=${data}`, requestOptions.get())
    .then(handleResponse)
    .then(data => {
      return data
    })
}

function getNote(data) {
  return fetch(ROOT + GET_NOTE_BY_CLASS + `?classID=${data}`, requestOptions.get())
    .then(handleResponse)
    .then(data => {
      return data
    })
}

function GetNoteByStudent(classID, studentID) {
  return fetch(ROOT + GET_NOTE_BY_STUDENT + `?classID=${classID}&studentID=${studentID}`, requestOptions.get())
    .then(handleResponse)
    .then(data => {
      return data
    })
}

function GetTestByStudent(classID, studentID) {
  return fetch(ROOT + GET_STUDENT_TEST_BY_STUDENT + `?classID=${classID}&studentID=${studentID}`, requestOptions.get())
    .then(handleResponse)
    .then(data => {
      return data
    })
}

function updateStudentTest(data) {
  return fetch(ROOT + UPDATE_STUDENT_TEST, requestOptions.put(data))
    .then(handleResponse)
    .then(data => {
      return data
    })
}

function updateExamNote(data) {
  return fetch(ROOT + UPDATE_EXAM_NOTE, requestOptions.put(data))
    .then(handleResponse)
    .then(data => {
      return data
    })
}


export const all_test = {
  getMasterType,
  getStudentTest,
  getNote,
  GetNoteByStudent,
  GetTestByStudent,
  updateStudentTest,
  updateExamNote,
}