var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0 my-5 bookTable",attrs:{"headers":_vm.displayType === 'done' ? _vm.normalHeaders : _vm.secondHeaders,"items":_vm.dataTable,"items-per-page":1000000,"hide-default-header":"","hide-default-footer":"","fixed-header":"","height":"59vh","id":"bookTable"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',{staticClass:"v-data-table-header",staticStyle:{"border-radius":"20px","text-align":"center"}},[_c('tr',_vm._l((props.headers),function(header,index){return _c('th',{key:index,style:({
            background: '#0b2585',
            color: '#fff',
            textAlign: 'center',
            width: ("" + (header.width)),
            maxWidth: ("" + (header.width)),
          }),domProps:{"innerHTML":_vm._s(header.text)}})}),0)])]}},{key:"item.no",fn:function(ref){
          var index = ref.index;
return [_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(index + 1))])]}},{key:"item.paidDate",fn:function(ref){
          var item = ref.item;
return [_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("getDate")(item.paidDate)))])]}},{key:"item.receiveDate",fn:function(ref){
          var item = ref.item;
return [_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("getDate")(item.receiveDate)))])]}},{key:"item.dob",fn:function(ref){
          var item = ref.item;
return [_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("getDate")(item.dob)))])]}},{key:"item.studentName",fn:function(ref){
          var item = ref.item;
return [_c('p',{staticClass:"link text-center",on:{"click":function($event){return _vm.viewDetailUser(item.stuUserID)}}},[_vm._v(_vm._s(item.studentName))])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }