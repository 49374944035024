var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?[_c('div',{staticClass:"pb-3"},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1)]:_vm._e(),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"elevation-0",attrs:{"color":"primary"},on:{"click":function($event){return _vm.downloadExcel()}}},[_vm._v("Download Excel File")])],1),(!_vm.isLoading)?_c('v-data-table',{staticClass:"elevation-0 my-5 bookTable",attrs:{"headers":_vm.headers,"items":_vm.dataTable,"items-per-page":1000000,"hide-default-header":"","hide-default-footer":"","fixed-header":"","height":"49vh","id":"bookTable"},scopedSlots:_vm._u([{key:"header",fn:function(ref){return [_c('thead',{staticClass:"v-data-table-header",staticStyle:{"border-radius":"20px","text-align":"center"}},[_c('tr',_vm._l((_vm.headers),function(header,index){return _c('th',{key:index,style:({
              background: '#0b2585',
              color: '#fff',
              textAlign: 'center',
              width: ("" + (header.width)),
              maxWidth: ("" + (header.width)),
            }),domProps:{"innerHTML":_vm._s(header.text)}})}),0)])]}},{key:"body",fn:function(ref){
            var items = ref.items;
return [(items.length === 0)?_c('tbody',{staticStyle:{"text-align":"center"}},[_c('div',{staticClass:"pt-3",staticStyle:{"position":"absolute","left":"50%","transform":"translate(-50%, 0)"}},[_vm._v(" No data available ")])]):_c('tbody',_vm._l((items),function(item,index){return _c('tr',{key:index,staticClass:"row-sale"},[_c('td',[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.productCode)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.description)+" ")]),_c('td',[_c('div',{staticClass:"d-flex align-center justify-center"},[_vm._v(" "+_vm._s(((item.paidQuantity) + " out of " + (item.numOfStudent) + " students"))+" "),_c('img',{staticClass:"link ml-3",attrs:{"src":require("../../assets/images/icon-eye.svg")},on:{"click":function($event){return _vm.$refs.viewMaterialDetail.open(
                    item.classID,
                    item.productID,
                    item.numOfStudent,
                    item.receivedQuantity,
                    item.paidQuantity,
                    item.description,
                    'paid'
                  )}}})])]),_c('td',[_c('div',{staticClass:"d-flex align-center justify-center"},[_vm._v(" "+_vm._s(((item.receivedQuantity) + " out of " + (item.numOfStudent) + " students "))+" "),_c('img',{staticClass:"link ml-3",attrs:{"src":require("../../assets/images/icon-eye.svg")},on:{"click":function($event){return _vm.$refs.viewMaterialDetail.open(
                    item.classID,
                    item.productID,
                    item.numOfStudent,
                    item.receivedQuantity,
                    item.paidQuantity,
                    item.description,
                    'received'
                  )}}})])])])}),0)]}}],null,false,1686439722)}):_vm._e(),_c('CourseMaterialPopup',{ref:"viewMaterialDetail",attrs:{"maxWidth":"60vw","maxHeight":"60vh","studentList":_vm.studentList}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }