<template>
  <div v-if="currentRole === 4 || currentRole === 5" class="d-flex flex-column">
    <v-row no-gutters>
      <v-col md="12">
        <div class="pa-10 pt-5">
          <div class="d-flex">
            <h2 v-if="!$route.query.classID" class="pt-1">Student Exam Result</h2>
          </div>
          <div v-if="!$route.query.classID" class="d-flex justify-space-between mt-3">
            <div class="d-flex">
              <v-autocomplete
                v-model="locationID"
                item-text="location"
                append-icon="keyboard_arrow_down"
                item-value="locationID"
                :items="centers"
                label="Center"
                outlined
                :class="'mr-2'"
                dense
              >
              </v-autocomplete>
              <v-autocomplete
                label="Class status"
                v-model="classStatusID"
                append-icon="keyboard_arrow_down"
                :items="statusList"
                item-text="classStatus"
                item-value="classStatusID"
                outlined
                dense
                hide-details
                class="mr-2"
              ></v-autocomplete>
              <v-autocomplete
                label="Class code"
                v-model="classID"
                append-icon="keyboard_arrow_down"
                :items="classList"
                item-text="classCode"
                item-value="classID"
                outlined
                dense
                hide-details
                class="mr-2"
              ></v-autocomplete>
              <v-btn class="primary ml-3" @click="searchExams">Search</v-btn>
            </div>
          </div>
          <div v-if="!$route.query.classID" class="d-flex align-center mb-3">
            <h3 v-if="filtered.length != 1">Total: {{ filtered.length }} students</h3>
            <h3 v-else>Total: {{ filtered.length }} student</h3>
          </div>
          <v-progress-linear
            v-if="isLoading"
            indeterminate
            color="primary"
            class="my-10"
          ></v-progress-linear>
          <v-data-table
            v-if="!isLoading"
            :headers="logHeaders.header"
            :items="filtered"
            :page.sync="page"
            :items-per-page="1000000000000000"
            hide-default-footer
            hide-default-header
            @page-count="pageCount = $event"
            class="elevation-0 exam-table"
            id="overall-exam-table"
            fixed-header
            height="75vh"
            style="border: 1px solid #fff"
          >
            <template v-slot:header="{}">
              <thead style="border-radius: 20px; text-align: center" class="exam-header">
                <tr>
                  <th
                    v-for="(header, index) in logHeaders.headerTitle"
                    :key="index"
                    class="w-150 exam-master-type-header"
                    style="
                      background: #0b2585;
                      color: #fff;
                      text-align: center;
                      border: 1px solid #fafaff;
                    "
                    :colspan="header.child.length === 0 ? 1 : header.child.length"
                    :rowspan="header.child.length === 0 ? 2 : 1"
                    v-html="header.text"
                  ></th>
                </tr>
                <tr>
                  <template v-for="(header, index) in logHeaders.headerTitle">
                    <th
                      :key="index + headerChild"
                      v-for="headerChild in header.child"
                      :style="{
                        textAlign: 'center',
                        zIndex: '5',
                        border: '1px solid #fafaff',
                        top: '49px',
                      }"
                      v-html="headerChild"
                    ></th>
                  </template>
                </tr>
              </thead>
              <tr class="exam-filter">
                <td
                  v-for="(header, index) in logHeaders.header"
                  :key="index"
                  :style="{
                    position: 'sticky',
                    // top: `${$route.query.classID ? '156px':'136px'}`,
                    top: checkHeaderLength.length < 19 ? '110px' : '136px',
                    background: '#ffff',
                  }"
                >
                  <div :class="'d-flex justify-center align-center'" v-if="filters[header.value]">
                    <Autocomplete
                      v-if="filters.hasOwnProperty(header.value)"
                      @setFilter="setFilter"
                      :styleBorder="'border-bottom'"
                      :keyFilter="header.value"
                      :selectedInit="filters[header.value]"
                      :listItem="
                        header.value === 'note'
                          ? ['Note', 'No Note']
                          : groupColumnValueList(header.value)
                      "
                    />
                  </div>
                  <div v-else>
                    <td></td>
                  </div>
                </td>
              </tr>
            </template>
            <template v-slot:body="{ items }">
              <tbody v-if="items.length === 0" style="text-align: center">
                <div
                  class="pt-3"
                  style="position: absolute; left: 50%; transform: translate(-50%, 0)"
                >
                  No data available
                </div>
              </tbody>
              <tbody v-else :class="'exam-body'">
                <tr v-for="(item, index) in items" :key="item.student_UserID">
                  <td :class="'stickyttt'" :style="{ width: '20px' }">
                    <p :style="{ width: '20px', textAlign: 'center' }">
                      {{ index + 1 }}
                    </p>
                  </td>
                  <td :class="'link'">
                    <p
                      :style="{ width: '100px', textAlign: 'center' }"
                      @click="viewDetailProfile(item.student_UserID)"
                    >
                      {{ item.studentCode }}
                    </p>
                  </td>
                  <td :class="'link'">
                    <p :style="{ width: '200px' }" @click="viewDetailProfile(item.student_UserID)">
                      {{ item.studentName }}
                    </p>
                  </td>
                  <td>
                    <p :style="{ width: '100px', textAlign: 'center' }">
                      {{ _dayjs(item.dob).format('DD/MM/YYYY') }}
                    </p>
                  </td>
                  <td>
                    <p :style="{ width: '100px', textAlign: 'center' }">
                      {{ _dayjs(item.actualStartDate).format('DD/MM/YYYY') }}
                    </p>
                  </td>
                  <td :class="'link'">
                    <p
                      :style="{ width: '120px', textAlign: 'center' }"
                      @click="viewDetailClass(item.classID)"
                    >
                      {{ item.classCode }}
                    </p>
                  </td>
                  <td :class="'link'">
                    <p
                      :style="{ width: '170px', textAlign: 'center' }"
                      @click="viewDetailProfile(item.qcid)"
                    >
                      {{ item.qcName }}
                    </p>
                  </td>
                  <td>
                    <p
                      v-if="item.ecname"
                      :class="'link'"
                      :style="{ width: '170px', textAlign: 'center' }"
                      @click="viewDetailProfile(item.ecUserId)"
                    >
                      {{ item.ecname }}
                    </p>
                    <p class="text-center" v-else>-</p>
                  </td>

                  <td v-for="test in item.tests" :key="test.testID">
                    <div
                      class="d-flex justify-space-between align-center"
                      style="width: 120px"
                      v-if="getLastTest(item.tests, test)"
                    >
                      <div v-if="test.mark">
                        <p>
                          {{ test.mark }}
                        </p>
                      </div>
                      <div v-else>
                        <p>-</p>
                      </div>
                      <div>
                        <img
                          @click="getTestInfo(item.tests, test), $refs.viewDetailTest.open()"
                          :src="
                            checkIcon(item.tests, test)
                              ? showIcon(item.tests, test) === 'Public'
                                ? iconPublic
                                : iconDraft
                              : iconNone
                          "
                          alt=""
                          srcset=""
                        />
                      </div>
                    </div>
                    <div class="d-flex justify-center align-center" v-else>
                      <div v-if="test.mark">
                        <p>
                          {{ test.mark }}
                        </p>
                      </div>
                      <div v-else>
                        <p>-</p>
                      </div>
                    </div>
                  </td>
                  <td class="d-flex justify-center align-center">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <img
                          v-if="!item.note"
                          v-bind="attrs"
                          v-on="on"
                          :style="{ width: '18px' }"
                          @click="openEditNote(item)"
                          src="../../../assets/images/done-note.svg"
                          alt=""
                        />
                        <img
                          v-else
                          v-bind="attrs"
                          v-on="on"
                          :style="{ width: '18px' }"
                          @click="openEditNote(item)"
                          src="../../../assets/images/notes.svg"
                          alt=""
                        />
                      </template>
                      <span v-if="item.note === '' || item.note === null || item.note === undefined"
                        >Create note</span
                      >
                      <span v-else>View note</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </div>
        <div v-show="overlay" :class="'h'">
          <div class="card-i">
            <v-card :style="{ boxShadow: 'none' }" light>
              <v-card-title class="text-h5" :style="{ fontWeight: 700 }">
                Student Exam Note
              </v-card-title>
              <v-divider></v-divider>
              <v-textarea
                :v-model="noteText"
                name="input-7-1"
                label="Add Student Exam Result Note"
                :disabled="!isEdit"
                :value="noteText"
                @change="noteOnChange"
                outlined
                dense
                height="30vh"
                class="mt-3 ml-5 mr-5"
              ></v-textarea>
              <v-divider></v-divider>

              <v-card-actions v-if="currentRole === 4 || currentRole === 5">
                <v-spacer></v-spacer>
                <div :class="'mb-4'" class="btnNote">
                  <v-btn elevation="2" large @click="overlay = false">Cancel</v-btn>
                  <v-btn
                    elevation="2"
                    large
                    color="primary"
                    @click="saveNote"
                    v-if="isEdit === true"
                    >Save</v-btn
                  >
                  <v-btn elevation="2" large color="primary" @click="btn_Edit" v-else>Edit</v-btn>
                </div>
              </v-card-actions>
            </v-card>
          </div>
        </div>
        <TestDialog
          maxWidth="50vw"
          maxHeight="80vh"
          :testInfo="testInfo"
          :videoUrl="videoUrl"
          :teacherComment="teacherComment"
          ref="viewDetailTest"
          @callData="getStudentTestWithScroll"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { class_managerment } from '@/api/class-management.js'
import { statistic_management } from '@/api/statistic-management.js'
import { all_test } from '@/api/all-test.js'
import dayjs from 'dayjs'
import QueryString from 'qs'
import iconPublic from '../../../assets/images/exam-result-public.svg'
import iconDraft from '../../../assets/images/exam-result-draft.svg'
import iconNone from '../../../assets/images/exam-result-none.svg'
import { getObjectPermission } from '../../../common/index'
import Autocomplete from '@/components/Utils/Autocomplete.vue'
import TestDialog from './TestDialog.vue'
export default {
  components: {
    Autocomplete,
    TestDialog,
  },
  data() {
    return {
      currentRole: JSON.parse(localStorage.getItem('currentUser')).userInfo.accountTypeID,
      classStatusID: 3,
      numberRules: [v => !!v || 'This filed is required', v => !isNaN(v) || 'Must be number'],
      textRules: [v => !!v || 'This filed is required'],
      permision: getObjectPermission(439),
      isEdit:false,
      iconPublic: iconPublic,
      iconDraft: iconDraft,
      iconNone: iconNone,
      centers: JSON.parse(localStorage.getItem('currentUser')).locationPermissions,
      locationID: JSON.parse(localStorage.getItem('currentCenter')),
      listNote: [],
      filters: {
        studentCode: [],
        studentName: [],
        dob: [],
        actualStartDate: [],
        classCode: [],
        qcName: [],
        ecname: [],
        note: [],
      },
      isLoading: false,
      overlay: false,
      noteText: '',
      recordID: 0,
      studentID: 0,
      checkHeaderLength: null,
      testInfo: [],
      videoUrl: '',
      teacherComment: '',
      classList: [],
      statusList: [],
      classStatus: '',
      masterType: [],
      studentTestList: [],
      testDate: [],
      page: 1,
      pageCount: 0,
      scrollTo: null,
      classID: this.$route.query.classID ? this.$route.query.classID : 5054,
    }
  },
  computed: {
    filtered() {
      return this.studentTestList?.filter(d => {
        return Object.keys(this.filters).every(f => {
          return (
            this.filters[f].length < 1 ||
            (f === 'note'
              ? this.filters[f].reduce((value, el) => {
                  if (d.note?.length > 0 && el === 'Note') {
                    value = true
                  }
                  if ((d.note === '' || d.note === null) && el === 'No Note') {
                    value = true
                  }
                  return value
                }, false)
              : this.filters[f].includes(d[f]))
          )
        })
      })
    },
    logHeaders() {
      //Header have value to filt
      const header = [
        {
          text: 'No',
          value: 'no',
          sortable: false,
          width: '60px',
        },
        {
          text: 'Student code',
          value: 'studentCode',
          sortable: false,
          width: '100px',
        },
        {
          text: 'Student name',
          value: 'studentName',
          sortable: false,
          width: '140px',
        },
        {
          text: 'DOB',
          value: 'dob',
          sortable: false,
          width: '100px',
        },
        {
          text: 'Start date',
          value: 'actualStartDate',
          sortable: false,
          width: '100px',
        },
        {
          text: 'Class code',
          value: 'classCode',
          sortable: false,
          width: '100px',
        },
        {
          text: 'QC',
          value: 'qcName',
          sortable: false,
          width: '140px',
        },
        {
          text: 'EC',
          value: 'ecname',
          sortable: false,
          width: '140px',
        },
      ]
      //header to render UI
      const headerTitle = header.map(el => {
        return {
          text: el.text,
          child: [],
        }
      })
      this.masterType.forEach(el => {
        headerTitle.push({
          text: el.testTypeCategory,
          child: el.tests.map(
            el => el.testType + '</br>' + dayjs(el.testDate).format('DD/MM/YYYY')
          ),
        })
      })
      headerTitle.push({
        text: 'Note',
        child: [],
      })
      //add value to headerValue for filtering purpose
      this.masterType.forEach(el => {
        el.tests.forEach(child => {
          header.push({
            text: child.testType + child.testID,
            value: child.mark,
            sortable: false,
            width: '50px',
          })
          this.filters[child.testType + child.testID] = []
        })
      })
      header.push({
        text: 'Note',
        value: 'note',
        sortable: false,
        width: '80px',
      })

      return { headerTitle, header }
    },
  },
  created() {
    // Using query param here to specific case for All-test in Acedemic
    // Because this component used in Detail class too
    if (!this.$route.query.classID) {
      if (localStorage.getItem('examResultStatus')) {
        this.classStatusID = JSON.parse(localStorage.getItem('examResultStatus'))
      }
      if (localStorage.getItem('examResultLocation')) {
        this.locationID = JSON.parse(localStorage.getItem('examResultLocation'))
      }
      if (localStorage.getItem('examResultClassID')) {
        this.classID = JSON.parse(localStorage.getItem('examResultClassID'))
      }
      this.searchClass()
    }
    //Specific for All-test
    else {
      this.getMasterTypeInClass()
      this.getStudentTest()
    }
    //FOR REDIRECTING NOTIFICATION
    // setTimeout(()=>{
    //   // const masterTypeID = 829
    //   const testTypeID = 831
    //   const studentID = 11916

    //   const getStudentTest = this.studentTestList.find(el=>el.studentID === studentID).tests
      
    //   const firstTest = getStudentTest.find(el=>el.testTypeID === testTypeID)
    //   this.getTestInfo(getStudentTest,firstTest)
    //   this.$refs.viewDetailTest.open()
    // },5000)
  },

  methods: {
    scrollToTheLeft() {
      /**
       * !somehow this needed to be setTimeout & don't clear it
       */
      this.$nextTick(()=>{
        const tableWrapper = document.querySelector('#overall-exam-table > div')
        const allHeader = document.querySelectorAll('.exam-master-type-header')
        const targetIndex = Array.from(allHeader).findIndex(
          el => el.innerText === this.testInfo[0].masterType
        )
        const targetElement = Array.from(allHeader)[targetIndex - 1]
        if (!targetElement) return
        const scrollLeft = targetElement.offsetLeft - tableWrapper.offsetLeft - 100
        tableWrapper.scrollBy({
          left: scrollLeft,
          behavior: 'smooth',
        })
      })
      // setTimeout(() => , 0)
    },
    searchExams() {
      this.testDate = []
      localStorage.setItem('examResultLocation', this.locationID)
      localStorage.setItem('examResultStatus', this.classStatusID)
      localStorage.setItem('examResultClassID', JSON.stringify(this.classID))
      this.getMasterTypeInClass()
      this.getStudentTest()
    },
    setFilter(objectFilterChange) {
      this.filters = {
        ...this.filters,
        [objectFilterChange.name]: objectFilterChange.filter,
      }
    },

    groupColumnValueList(val) {
      return this.studentTestList.map(d => d[val]).filter(y => y)
    },

    viewDetailProfile(userId) {
      this.$router.push({
        name: 'account-management-view-account',
        params: {
          userId: userId,
        },
      })
    },
    viewDetailClass(classID) {
      this.$router.push({
        name: 'class-management-detail',
        query: { classID: classID },
      })
    },

    getLastTest(list, item) {
      const sameRootTest = list.filter(el => el.masterTypeID === item.masterTypeID)
      return sameRootTest[sameRootTest.length - 1].testID === item.testID
    },
    getElementVal(arr, str) {
      return arr.map(el => el[str]).filter(y => y)
    },
    getTestInfo(list, item) {
      const sameRootTest = list.filter(el => el.masterTypeID === item.masterTypeID)
      this.testInfo = sameRootTest.map(el => el)
      this.videoUrl =
        this.getElementVal(this.testInfo, 'videoUrl').length > 0
          ? this.getElementVal(this.testInfo, 'videoUrl')[0]
          : ''
      this.teacherComment =
        this.getElementVal(this.testInfo, 'teacherComment').length > 0
          ? this.getElementVal(this.testInfo, 'teacherComment')[0]
          : ''
    },
    checkIcon(list, item) {
      const sameRootTest = list.filter(el => el.masterTypeID === item.masterTypeID)
      return (
        this.getElementVal(sameRootTest, 'videoUrl')[0] ||
        this.getElementVal(sameRootTest, 'mark')[0] ||
        this.getElementVal(sameRootTest, 'teacherComment')[0]
      )
    },
    showIcon(list, item) {
      const sameRootTest = list.filter(el => el.masterTypeID === item.masterTypeID)
      return this.getElementVal(sameRootTest, 'testResultStatus')[0]
    },

    openEditNote(item) {
      if (item.note == null || item.note == '' || item.note === undefined) this.isEdit = true
      else this.isEdit = false
      this.overlay = true
      this.studentID = item.studentID
      this.noteText = item.note
      this.recordID = item.noteID
    },
    noteOnChange(value) {
      this.noteText = value
    },
    async saveNote() {
      const body = this.recordID
        ? {
            classID: this.classID,
            studentID: this.studentID,
            teacherComment: this.noteText,
            recordID: this.recordID,
          }
        : {
            classID: this.classID,
            studentID: this.studentID,
            teacherComment: this.noteText
          }
      await all_test.updateExamNote(body)
      this.getMasterTypeInClass()
      this.getStudentTest()
      this.overlay = false
    },

    btn_Edit() {
      this.isEdit = true
    },
    async searchClass() {
      let body = {}
      await class_managerment.getClassStatus().then(res => {
        if (res) {
          res.unshift({
            classStatusID: 6,
            classStatus: 'All',
          })
          this.statusList = res
          this.classStatus = this.classStatusID < 6 ? res[this.classStatusID].classStatus : ''
          body = {
            pLocationid: this.locationID,
            pStatus: this.classStatus,
          }
        }
      })
      await class_managerment.getClassByLocation(QueryString.stringify(body)).then(res => {
        if (res.length > 0) {
          this.classID = res[0].classID
          this.classList = res
        } else {
          this.classList = []
        }
      })
    },
    async getMasterTypeInClass() {
      this.isLoading = true
      await all_test.getMasterType(this.classID).then(res => {
        if (res.length > 0) {
          const testTypeCategories = res.map(item => item.testType)

          // Step 2: Find the longest value from the extracted array
          this.checkHeaderLength = testTypeCategories.reduce(
            (longest, current) => (current.length > longest.length ? current : longest),
            ''
          )
          // Create an empty array to store merged information
          const mergedInfo = []
          res.forEach(el => {
            this.testDate.push(el.testDate)
          })
          // Iterate through the array and merge information based on testTypeCategory
          res.forEach(element => {
            const { testTypeCategory, classCode, classID, testTypeCategoryID } = element

            // Check if the testTypeCategory already exists in the mergedInfo array
            const existingElement = mergedInfo.find(
              el => el.testTypeCategoryID === testTypeCategoryID
            )

            // If the testTypeCategory already exists, push the current element to its "tests" array
            if (existingElement) {
              existingElement.tests.push(element)
            } else {
              // If the testTypeCategory doesn't exist, create a new object and add it to the mergedInfo array
              const newElement = {
                testTypeCategoryID,
                testTypeCategory,
                classCode,
                classID,
                tests: [element],
              }
              mergedInfo.push(newElement)
            }
          })
          mergedInfo.forEach(element => {
            element.tests.sort((a, b) => a.testID - b.testID)
          })
          this.masterType = mergedInfo
        } else this.masterType = []
      })
    },
    async getStudentTest() {
      this.isLoading = true
      await all_test.getStudentTest(this.classID).then(res => {
        if (res.length > 0) {
          // Create an empty array to store merged information
          const mergedInfo = []

          // Iterate through the array and merge information based on testTypeCategory
          res.forEach(element => {
            const {
              studentName,
              studentCode,
              studentID,
              classCode,
              classID,
              qcName,
              qcid,
              ecname,
              ecUserId,
              dob,
              actualStartDate,
              student_UserID,
            } = element

            // Check if the testTypeCategory already exists in the mergedInfo array
            const existingElement = mergedInfo.find(el => el.studentID === studentID)

            // If the testTypeCategory already exists, push the current element to its "tests" array
            if (existingElement) {
              existingElement.tests.push(element)
            } else {
              // If the testTypeCategory doesn't exist, create a new object and add it to the mergedInfo array
              const newElement = {
                ecname,
                ecUserId,
                dob,
                actualStartDate,
                studentName,
                studentCode,
                studentID,
                classCode,
                classID,
                qcName,
                qcid,
                student_UserID,
                tests: [element],
              }
              mergedInfo.push(newElement)
            }
          })
          this.testDate.forEach((date, index) => {
            mergedInfo.forEach(el => {
              if (el.tests && el.tests[index]) {
                el.tests[index].testDate = date
              }
            })
          })
          const testTypeIDs = this.masterType.flatMap(item =>
            item.tests.map(test => test.testTypeID)
          )

          mergedInfo.forEach(el =>
            el.tests.sort(
              (a, b) => testTypeIDs.indexOf(a.testTypeID) - testTypeIDs.indexOf(b.testTypeID)
            )
          )
          this.studentTestList = mergedInfo
        } else {
          this.studentTestList = []
          this.isLoading = false
        }
      })
      await statistic_management.getStudentList(this.classID).then(res => {
        const presentStudent = res.map(el => el.studentID)
        this.studentTestList = this.studentTestList.filter(el =>
          presentStudent.includes(el.studentID)
        )
      })
      await all_test.getNote(this.classID).then(res => {
        this.studentTestList.forEach(obj1 => {
          const obj2 = res.find(obj => obj.studentID === obj1.studentID)
          if (obj2) {
            obj1.note = obj2.teacherComment
            obj1.noteID = obj2.recordID
          }
        })
        this.isLoading = false
      })
    },
    async getStudentTestWithScroll() {
      this.isLoading = true
      await all_test.getStudentTest(this.classID).then(res => {
        if (res.length > 0) {
          // Create an empty array to store merged information
          const mergedInfo = []

          // Iterate through the array and merge information based on testTypeCategory
          res.forEach(element => {
            const {
              studentName,
              studentCode,
              studentID,
              classCode,
              classID,
              qcName,
              qcid,
              ecname,
              ecUserId,
              dob,
              actualStartDate,
              student_UserID,
            } = element

            // Check if the testTypeCategory already exists in the mergedInfo array
            const existingElement = mergedInfo.find(el => el.studentID === studentID)

            // If the testTypeCategory already exists, push the current element to its "tests" array
            if (existingElement) {
              existingElement.tests.push(element)
            } else {
              // If the testTypeCategory doesn't exist, create a new object and add it to the mergedInfo array
              const newElement = {
                ecname,
                ecUserId,
                dob,
                actualStartDate,
                studentName,
                studentCode,
                studentID,
                classCode,
                classID,
                qcName,
                qcid,
                student_UserID,
                tests: [element],
              }
              mergedInfo.push(newElement)
            }
          })
          this.testDate.forEach((date, index) => {
            mergedInfo.forEach(el => {
              if (el.tests && el.tests[index]) {
                el.tests[index].testDate = date
              }
            })
          })
          const testTypeIDs = this.masterType.flatMap(item =>
            item.tests.map(test => test.testTypeID)
          )

          mergedInfo.forEach(el =>
            el.tests.sort(
              (a, b) => testTypeIDs.indexOf(a.testTypeID) - testTypeIDs.indexOf(b.testTypeID)
            )
          )
          this.studentTestList = mergedInfo
        } else {
          this.studentTestList = []
          this.isLoading = false
        }
      })
      await statistic_management.getStudentList(this.classID).then(res => {
        const presentStudent = res.map(el => el.studentID)
        this.studentTestList = this.studentTestList.filter(el =>
          presentStudent.includes(el.studentID)
        )
      })
      await all_test.getNote(this.classID).then(res => {
        this.studentTestList.forEach(obj1 => {
          const obj2 = res.find(obj => obj.studentID === obj1.studentID)
          if (obj2) {
            obj1.note = obj2.teacherComment
            obj1.noteID = obj2.recordID
          }
        })
        this.isLoading = false
        this.scrollToTheLeft()
      })
    },
    _dayjs: dayjs,
  },
  watch: {
    locationID: function () {
      this.searchClass()
    },
    classStatusID: function () {
      this.searchClass()
    },

  },
}
</script>
<style lang="scss" scoped>
.link {
  cursor: pointer;

  color: #427df2 !important;
}
.w-150 {
  width: 100px;
}
.link:hover {
  text-decoration: underline;
  font-weight: 700;
}

.btnNote {
  float: right;
  margin-top: 20px;
}

.btnNote button {
  margin-left: 20px;
  min-width: 150px !important;
}
.card-i {
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.h {
  background: rgba(0, 0, 0, 0.01);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1000;
  left: 0;
}
.exam-table {
  table-layout: fixed;
  border-top: 1px solid #dae3e8 !important;
}
.exam-header th {
  color: #fff !important;
  background: #0c2e68 !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  text-align: center;
}
.exam-header th:nth-child(1) {
  position: sticky;
  left: 0;
  z-index: 6;
}
.exam-header th:nth-child(2) {
  position: sticky;
  left: 50px;
  z-index: 6;
}
.exam-header th:nth-child(3) {
  position: sticky;
  left: 170px;
  z-index: 6;
}

.exam-filter td:nth-of-type(1) {
  z-index: 4 !important;
  left: 0;
}
.exam-filter td:nth-of-type(2) {
  z-index: 4 !important;
  left: 50px;
}
.exam-filter td:nth-of-type(3) {
  z-index: 4 !important;
  left: 170px;
}

.exam-body tr:nth-child(even) {
  background: #eaf1ff;
}
.exam-body tr td:nth-child(1) {
  position: sticky;
  left: 0;
}
.exam-body tr td:nth-child(2) {
  position: sticky;
  // left: 100%;
  left: 50px;
}
.exam-body tr td:nth-child(3) {
  position: sticky;
  left: 170px;
}
.exam-body tr:nth-child(even) td:nth-child(3) {
  background: #eaf1ff !important;
}
.exam-body tr:nth-child(odd) td:nth-child(3) {
  background: #fff !important;
}
.exam-body tr:nth-child(even) td:nth-child(2) {
  background: #eaf1ff !important;
}
.exam-body tr:nth-child(odd) td:nth-child(2) {
  background: #fff !important;
}
.exam-body tr:nth-child(even) td:nth-child(1) {
  background: #eaf1ff !important;
}
.exam-body tr:nth-child(odd) td:nth-child(1) {
  background: #fff !important;
}
.exam-body tr:nth-child(even) {
  background: #eaf1ff !important;
}
.exam-body tr:nth-child(odd) {
  background: #fff !important;
}
</style>
