<template>
  <v-dialog v-if="transDialog" v-model="transDialog" :max-width="maxWidth || 515">
    <v-card class="pa-5" :style="{ aspectRatio: '4/3', overflowY: 'hidden' }">
      <template v-if="isLoading">
        <div class="pb-3">
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </div>
      </template>
      <div class="d-flex flex-column">
        <div class="d-flex align-center justify-start mb-7">
          <h1 class="text-center flex-grow-1">{{ bookName }}</h1>
          <v-btn icon @click="closePopup"><v-icon>mdi-close</v-icon></v-btn>
        </div>
      </div>
      <v-tabs v-model="tab" class="mb-6" style="box-shadow: 0px 9px 2px -9px #000">
        <v-tab :href="'#tab1'"> {{ `${this.type === 'paid' ? 'PAID' : 'RECEIVED'}` }} </v-tab>
        <v-tab :href="'#tab2'"> {{ `${this.type === 'paid' ? 'UNPAID' : 'UNRECEIVED'}` }} </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item :value="'tab1'">
          <h3>
            <strong>{{ `${this.type === 'paid' ? this.paidNbr : this.receivedNbr}` }}</strong> out
            of {{ this.studentNbr }} students
          </h3>
          <PopupTable
            :dataTable="
              type === 'paid'
                ? dataTable.filter(el => el.paidDate)
                : dataTable.filter(el => el.receiveDate)
            "
            :displayType="'done'"
            :statusType="type"
          />
        </v-tab-item>
        <v-tab-item :value="'tab2'">
          <h3>
            <strong>{{
              `${
                this.type === 'paid'
                  ? this.studentNbr - this.paidNbr
                  : this.studentNbr - this.receivedNbr
              }`
            }}</strong>
            out of {{ this.studentNbr }} students
          </h3>
          <PopupTable
            :dataTable="
              dataTable.length === 0
                ? studentData
                : type === 'paid'
                ? studentData.filter(
                    el =>
                      !dataTable
                        .filter(y => y.paidDate)
                        .map(el => el.studentID)
                        .includes(el.studentID)
                  )
                : studentData.filter(
                    el =>
                      !dataTable
                        .filter(y => y.receiveDate)
                        .map(el => el.studentID)
                        .includes(el.studentID)
                  )
            "
            :displayType="'undone'"
            :statusType="type"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import { book_management } from '@/api/book_management'
import ViewDetail from '@/mixins/viewDetail.js'
import ConfigDate from '@/mixins/configDate.js'
import PopupTable from './MaterialPopupTable.vue'

export default {
  mixins: [ConfigDate, ViewDetail],
  props: {
    maxWidth: String,
    maxHeight: String,
    studentList: Array,
  },
  components: { PopupTable },
  data() {
    return {
      currentRole: JSON.parse(localStorage.getItem('currentUser')).userInfo.accountTypeID,
      dataTable: [],
      studentData: [],
      tab: 'tab1',
      studentNbr: 0,
      type: '',
      receivedNbr: 0,
      paidNbr: 0,
      bookName: '',
      transDialog: false,
      isLoading: false,
    }
  },
  computed: {},
  created() {},
  watch: {
    transDialog(val) {
      if (val === false) {
        this.transDialog = false
        this.dataTable = []
      }
    },
  },
  methods: {
    async open(
      classID,
      productID,
      numOfStudent,
      receivedQuantity,
      paidQuantity,
      description,
      data
    ) {
      this.isLoading = true
      this.classID = classID
      this.bookName = description
      this.studentNbr = numOfStudent
      this.receivedNbr = receivedQuantity
      this.paidNbr = paidQuantity
      this.type = data
      this.dataTable = await book_management.getClassMaterialDetail(classID, productID)
      this.studentData = this.studentList
      this.isLoading = false
      this.transDialog = true
    },
    closePopup() {
      this.transDialog = false
      this.tab = 'tab1'
      this.dataTable = []
      this.studentData = []
    },
  },
}
</script>
<style lang="scss" scoped>
.book-inventory h1 {
  font-size: 21px;
}

.select-item {
  // margin-right: 10px;
  max-width: 200px;
}
.multiple-select {
  margin-right: 10px;
  max-width: 400px;
}

.book-inventory {
  margin: 0 20px;
}

.fixed-book-filter {
  position: sticky;
  top: 58px;
  z-index: 2;
  background-color: #ffffff;
}
.row-overall {
  td {
    text-align: center;
    p {
      font-weight: bold;
      display: inline-block;
    }
  }
}
tbody {
  tr.row-sale {
    &:nth-child(odd) {
      background-color: #fff;
    }
    background-color: #f3faff;

    td {
      text-align: center;
    }
  }
}

.bookTableFoot {
  td {
    text-align: center;
    font-weight: bold;
  }
}
</style>
