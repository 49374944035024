var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.transDialog)?_c('v-dialog',{attrs:{"max-width":_vm.maxWidth || 515},model:{value:(_vm.transDialog),callback:function ($$v) {_vm.transDialog=$$v},expression:"transDialog"}},[_c('v-card',{staticClass:"pa-5",style:({ aspectRatio: '4/3', overflowY: 'hidden' })},[(_vm.isLoading)?[_c('div',{staticClass:"pb-3"},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1)]:_vm._e(),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex align-center justify-start mb-7"},[_c('h1',{staticClass:"text-center flex-grow-1"},[_vm._v(_vm._s(_vm.bookName))]),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closePopup}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]),_c('v-tabs',{staticClass:"mb-6",staticStyle:{"box-shadow":"0px 9px 2px -9px #000"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{attrs:{"href":'#tab1'}},[_vm._v(" "+_vm._s(("" + (this.type === 'paid' ? 'PAID' : 'RECEIVED')))+" ")]),_c('v-tab',{attrs:{"href":'#tab2'}},[_vm._v(" "+_vm._s(("" + (this.type === 'paid' ? 'UNPAID' : 'UNRECEIVED')))+" ")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"value":'tab1'}},[_c('h3',[_c('strong',[_vm._v(_vm._s(("" + (this.type === 'paid' ? this.paidNbr : this.receivedNbr))))]),_vm._v(" out of "+_vm._s(this.studentNbr)+" students ")]),_c('PopupTable',{attrs:{"dataTable":_vm.type === 'paid'
              ? _vm.dataTable.filter(function (el) { return el.paidDate; })
              : _vm.dataTable.filter(function (el) { return el.receiveDate; }),"displayType":'done',"statusType":_vm.type}})],1),_c('v-tab-item',{attrs:{"value":'tab2'}},[_c('h3',[_c('strong',[_vm._v(_vm._s(("" + (this.type === 'paid' ? this.studentNbr - this.paidNbr : this.studentNbr - this.receivedNbr))))]),_vm._v(" out of "+_vm._s(this.studentNbr)+" students ")]),_c('PopupTable',{attrs:{"dataTable":_vm.dataTable.length === 0
              ? _vm.studentData
              : _vm.type === 'paid'
              ? _vm.studentData.filter(
                  function (el) { return !_vm.dataTable
                      .filter(function (y) { return y.paidDate; })
                      .map(function (el) { return el.studentID; })
                      .includes(el.studentID); }
                )
              : _vm.studentData.filter(
                  function (el) { return !_vm.dataTable
                      .filter(function (y) { return y.receiveDate; })
                      .map(function (el) { return el.studentID; })
                      .includes(el.studentID); }
                ),"displayType":'undone',"statusType":_vm.type}})],1)],1)],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }