// const _ = require('lodash')
const dayjs = require('dayjs')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrAfter)
// const customParseFormat = require('dayjs/plugin/customParseFormat');

// dayjs.extend(customParseFormat);

export default {
    name: 'ConfigDate',
    filters: {
        getDate: function (value) {
            if (!value) return '-'
            return dayjs(value).format('DD/MM/YYYY')
        },
        getMonth: function (value) {
            if (!value) return '-'
            return dayjs(value).format('MM/YYYY')
        },
        getTime: function (value) {
            if (!value) return '-'
            return dayjs(value).format('HH:mm:ss')
        },
        getDateTime: function (value) {
            if (!value) return '-'
            return dayjs(value).format('DD/MM/YYYY') + ' ' + dayjs(value).format('HH:mm:ss')
        },
        showNotiDateTime: function (value) {
            if (!value) return '-'
            else if (dayjs(value).isSame(new Date(), 'day')) return dayjs(value).format('HH:mm')
            else return dayjs(value).format('DD/MM/YYYY')
        },
        getRecordDeadline: function (value) {
            if (!value) return '-'
            return new Date(
                new Date(value.split('T')[0]).getTime() + 86400000 * 14
            ).toISOString()
        },
        // getOriginDate: function (value) {
        //     if (!value) return '-'
        //     return dayjs(value).format('YYYY-MM-DD')
        // },
    },
    methods: {
        getRecordDeadline(value) {
            return new Date(
                new Date(value.split('T')[0]).getTime() + 86400000 * 14
            ).toISOString()
        },
        isDayBefore(date1, date2) {
            return dayjs(date1).isBefore(dayjs(date2))
        },
        isSameDay(date1, date2) {
            return dayjs(date1).isSame(date2, 'day')
        },
        getOriginDate(value) {
            return dayjs(value).format('YYYY-MM-DD')
        },
        isSameOrAfter(date1, date2) {
            return dayjs(date1).isSameOrAfter(dayjs(date2))
        },
        isSameOrAfterMonth(date1, date2) {
            return dayjs(date1).isSameOrAfter(dayjs(date2), 'month')
        },
        isSameOrAfterYear(date1, date2) {
            return dayjs(date1).isSameOrAfter(dayjs(date2), 'year')
        }
    },
}