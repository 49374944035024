<template>
  <div>
    <template v-if="isLoading">
      <div class="pb-3">
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </div>
    </template>
    <div class="d-flex justify-end">
      <v-btn color="primary" class="elevation-0" @click="downloadExcel()"
        >Download Excel File</v-btn
      >
    </div>
    <v-data-table
      v-if="!isLoading"
      :headers="headers"
      :items="dataTable"
      :items-per-page="1000000"
      hide-default-header
      hide-default-footer
      class="elevation-0 my-5 bookTable"
      fixed-header
      height="49vh"
      id="bookTable"
    >
      <template v-slot:header="{}">
        <thead style="border-radius: 20px; text-align: center" class="v-data-table-header">
          <tr>
            <th
              v-for="(header, index) in headers"
              :key="index"
              :style="{
                background: '#0b2585',
                color: '#fff',
                textAlign: 'center',
                width: `${header.width}`,
                maxWidth: `${header.width}`,
              }"
              v-html="header.text"
            ></th>
          </tr>
        </thead>
        <!-- <tr class="fixed-book-filter">
                  <th v-for="header in headers" :key="header.text">
                    <Autocomplete
                      v-if="autoFilters.hasOwnProperty(header.value)"
                      @setFilter="setAutoFilter"
                      :styleBorder="'border-bottom'"
                      :keyFilter="header.value"
                      :selectedInit="autoFilters[header.value]"
                      :listItem="groupColumnValueList(header.value)"
                    />
                  </th>
                </tr> -->
      </template>
      <template v-slot:body="{ items }">
        <tbody v-if="items.length === 0" style="text-align: center">
          <div class="pt-3" style="position: absolute; left: 50%; transform: translate(-50%, 0)">
            No data available
          </div>
        </tbody>
        <tbody v-else>
          <tr v-for="(item, index) in items" :key="index" class="row-sale">
            <td>
              {{ index + 1 }}
            </td>
            <td>
              {{ item.productCode }}
            </td>
            <td>
              {{ item.description }}
            </td>
            <td>
              <div class="d-flex align-center justify-center">
                {{ `${item.paidQuantity} out of ${item.numOfStudent} students` }}
                <img
                  class="link ml-3"
                  src="../../assets/images/icon-eye.svg"
                  @click="
                    $refs.viewMaterialDetail.open(
                      item.classID,
                      item.productID,
                      item.numOfStudent,
                      item.receivedQuantity,
                      item.paidQuantity,
                      item.description,
                      'paid'
                    )
                  "
                />
              </div>
            </td>
            <td>
              <div class="d-flex align-center justify-center">
                {{ `${item.receivedQuantity} out of ${item.numOfStudent} students ` }}
                <img
                  class="link ml-3"
                  src="../../assets/images/icon-eye.svg"
                  @click="
                    $refs.viewMaterialDetail.open(
                      item.classID,
                      item.productID,
                      item.numOfStudent,
                      item.receivedQuantity,
                      item.paidQuantity,
                      item.description,
                      'received'
                    )
                  "
                />
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <CourseMaterialPopup
      maxWidth="60vw"
      maxHeight="60vh"
      :studentList="studentList"
      ref="viewMaterialDetail"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { book_management } from '@/api/book_management'
import ViewDetail from '@/mixins/viewDetail.js'
import ConfigDate from '@/mixins/configDate.js'
import CourseMaterialPopup from './CourseMaterial/CourseMaterialPopup.vue'
// import Autocomplete from '@/components/Utils/Autocomplete.vue'

export default {
  mixins: [ConfigDate, ViewDetail],
  components: { CourseMaterialPopup },
  props: {
    classID: Number,
    tabID: String,
    classCode: String,
  },
  data() {
    return {
      currentRole: JSON.parse(localStorage.getItem('currentUser')).userInfo.accountTypeID,
      dataTable: [],
      studentList: [],
      isLoading: false,
      //   autoFilters: {
      //     productCode: [],
      //     description: [],
      //     paidStatus: [],
      //     receivedStatus: [],
      //     receivedDate: []
      //   },
      //setting table
      headers: [
        {
          text: 'No',
          value: 'no',
          width: '50px',
          sortable: false,
        },
        {
          text: 'Product Code',
          value: 'productCode',
          sortable: false,
        },
        {
          text: 'Product Name',
          value: 'description',
          width: '400px',
          sortable: false,
        },
        {
          text: 'Paid quantity',
          value: 'paidQuantity',
          width: '300px',
          sortable: false,
        },
        {
          text: 'Received quantity',
          value: 'receivedQuantity',
          width: '300px',
          sortable: false,
        },
      ],
    }
  },
  watch: {
    tabID: function (val) {
      if (val === 'tab8') this.init()
    },
  },
  computed: {
    // filtered() {
    //   return this.dataTable?.filter(d => {
    //     return Object.keys(this.autoFilters).every(f => {
    //       return this.autoFilters[f].length < 1 || this.autoFilters[f].includes(d[f])
    //     })
    //   })
    // },
  },
  created() {
    this.init()
  },
  methods: {
    // setAutoFilter(objectFilterChange) {
    //   this.autoFilters = {
    //     ...this.autoFilters,
    //     [objectFilterChange.name]: objectFilterChange.filter,
    //   }
    // },
    // groupColumnValueList(val) {
    //   if (val === 'qtyOrd') {
    //     return [...new Set(this.dataTable.map(d => d[val]))]
    //   }
    //   return this.dataTable.map(d => d[val]).filter(y => y)
    // },
    async downloadExcel() {
      const tableName = 'Course Materials of class ' + this.classCode
      await book_management.exportMaterialData(this.classID, tableName)
    },
    async init() {
      this.isLoading = true
      this.dataTable = await book_management.getClassMaterialStatus(this.classID)
      this.getStudentList()
      this.isLoading = false
    },
    async getStudentList() {
      const studentData = await book_management.getClassMaterialDetail(this.classID)
      this.studentList = Object.values(
        studentData.reduce((uniqueMap, student) => {
          uniqueMap[student.studentID] = student
          return uniqueMap
        }, {})
      )
    },

    _dayjs: dayjs,
  },
}
</script>
<style lang="scss" scoped>
.book-inventory h1 {
  font-size: 21px;
}

.select-item {
  // margin-right: 10px;
  max-width: 200px;
}
.multiple-select {
  margin-right: 10px;
  max-width: 400px;
}

.book-inventory {
  margin: 0 20px;
}

.fixed-book-filter {
  position: sticky;
  top: 58px;
  z-index: 2;
  background-color: #ffffff;
}
.row-overall {
  td {
    text-align: center;
    p {
      font-weight: bold;
      display: inline-block;
    }
  }
}
tbody {
  tr.row-sale {
    &:nth-child(odd) {
      background-color: #fff;
    }
    background-color: #f3faff;

    td {
      text-align: center;
    }
  }
}

.bookTableFoot {
  td {
    text-align: center;
    font-weight: bold;
  }
}
</style>
